.collapsible {
  /* base styles for the collapsible element */
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: var(--height);
}
.collapsible.open{
  /* additional styles for the collapsible element when it is open */
  border-top: 1px solid rgb(235, 235, 235);
  height: auto;
}
.collapsible li{
  margin-top: 10px;
}
.collapse-header, .collapse-header:focus,.collapse-header:hover {
  /* base styles for the collapsible header */
  width: 100%;
  font-size: inherit;
  cursor: pointer;
  background: var(--background);
  color: var(--color);
  fill: var(--color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Inter', sans-serif !important;
  padding: 0 !important;
}
.collapse-header:hover{
  /* additional styles for the collapsible header when it is hovered */
  color: var(--hover-color) !important;

}
.collapse-header.open{
  /* additional styles for the collapsible header when it is open */
  background: lightblue;
}

.collapse-header:hover {
  /* additional styles when the collapsible header is hovered */
  background: var(--hover-background);
}
.collapse-header:active {
  /* additional styles when the collapsible header is active */
  background: var(--active-background);
}
.collapse-header:focus {
  /* additional styles when the collapsible header is focused */
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.collapse-navlist .item{
  margin: 0 !important;
}