/* Style for the dropdown container */
.dropdown {
	position: relative;
	display: inline-block;
}

/* Style for the button that will be used as the dropdown trigger */
.dropdown button {
	background: transparent;
	padding: 0;
	border: none;
	display: flex;
	align-items: center;
	min-height: 12px;
	cursor: pointer;
}
.dropdown button:hover > *{
	color: var(--hover-color);
}
.dropdown button > .icon-container {
	align-self: flex-start;
	margin-left: 0.35rem;
}
.dropdown button > .icon-container {
	height: 15px;
	width: 15px;
}

.dropdown button > .icon-container svg {
	height: 100%;
	width: 100%;
	margin-top: 0.1rem;
}

.dropdown button > * {
	font-family: 'Inter', sans-serif;
	color: black;
	font-size: 15px;
	font-weight: 500 !important;
}

/* Style for the dropdown content (hidden by default) */
.dropdown-content {
	min-width: 200px;
	padding: 0 !important;
	display: none;
	position: absolute;
	background-color: #fff;
	border: 1px solid #d7d9e3;
	width: 200px;
	z-index: 1;
}
.dropdown-content ul {
	margin: 0 !important;
}
/* Style for each item in the dropdown */
.dropdown-content li {
	white-space: nowrap;
	color: black;
	padding: 0.7rem 1rem;
	display: block;
	text-decoration: none;
	cursor: pointer;
}

.dropdown-content li {
	margin: 0 !important;
}
/* Change color on hover */
.dropdown-content li:hover a {
	color: var(--hover-color) !important;
}
/* Show the dropdown content when the dropdown button is hovered over */
.dropdown:hover .dropdown-content {
	display: block;
}
