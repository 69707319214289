@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Roboto:ital,wght@0,400;1,500;1,700&display=swap');
body {
	margin: 0;
	color: #212b36;
	line-height: 1.5;
	font-size: 1rem;
	font-family: 'helvetica', sans-serif;
	font-weight: 400;
	background-color: #f9fafb;
}
.flex {
	display: flex;
}

:root {
	--row-gap: 8px;
}

@media screen and (min-width: 600px) {
	:root {
		--row-gap: 16px;
	}
}

@media screen and (min-width: 900px) {
	:root {
		--row-gap: 18px;
	}
}

@media screen and (min-width: 1200px) {
	:root {
		--row-gap: 32px;
	}
}

.flex > * + * {
	margin-left: var(--row-gap) !important;
}

.align-center {
	align-items: center;
}
.align-start {
	align-items: start;
}
.align-end {
	align-items: end;
}
.align-baseline {
	align-items: baseline;
}
.align-stretch {
	align-items: stretch;
}

.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-end {
	justify-content: flex-end;
}
.justify-start {
	justify-content: flex-start;
}
.justify-around {
	justify-content: space-around;
}
.nav-item {
	list-style: none;
	padding: 8px 16px;
	border-top: 1px solid #d7d9e3;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	white-space: nowrap;
}
.mobile-navlist .nav-item{
	margin: 0 !important;
}
.w-full {
	width: 100%;
}
.w-1\/2 {
	width: 50%;
}

li {
	list-style: none;
	color: black;
}

a{
	font-family: 'Inter', sans-serif !important;
	font-size: 15px;
	color: black;
	font-weight: 500 !important;
}
a:hover{
	/* font-family: 'Inter', sans-serif !important; */
	text-decoration: none !important;

}
.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeLarge {
	font-weight: 600 !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeLarge:focus {
	background-color: #01bafb;
}